import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Wordmark from '../../assets/digital-porters-wordmark.svg';
import BackgroundImage from '../../assets/nala-background.svg';
import theme from '../../styles';

function HeroVideo() {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight); // Update height on window resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AppContainer style={{ height: viewportHeight }}>
      {/* <Content>
        <StyledWordmark src={Wordmark} alt={'Digital Porters'} />
      </Content> */}
      <div></div>
      <H2>
        Conectando insumos <br />
        con ganaderos
      </H2>
      <Footer>
        <FooterLink href='mailto:sinergia@digitalporters.com'>
          nala@digitalporters.com
        </FooterLink>
      </Footer>
    </AppContainer>
  );
}

// Styled components
const AppContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // backgroundColor: theme.color.background,
  // backgroundImage: `url('https://d3m9aua57wabwh.cloudfront.net/sinergia-hero-background.webp')`,
  backgroundImage: `url('${BackgroundImage}')`,
  backgroundSize: 'cover', // Ensures the background image covers the container
  backgroundPosition: 'center', // Centers the image within the container
  backgroundRepeat: 'no-repeat', // Prevents tiling of the background image
}));

const BackgroundVideo = styled('video')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover', // Ensures the video covers the screen without distortion
  zIndex: -1,
}));

const Content = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  fontSize: '3rem',
  textAlign: 'center',
  fontFamily: "'Silkscreen', sans-serif",
  fontWeight: 700,
}));

const StyledWordmark = styled('img')(() => ({
  maxWidth: '1000px',
  width: '100%',
  '@media (max-width: 768px)': {
    maxWidth: '90%', // Adjust for smaller screens
  },
}));

const Footer = styled('div')(() => ({
  position: 'absolute',
  bottom: '20px', // Positions it 20px above the bottom of the screen
  width: '100%',
  textAlign: 'center',
}));

const FooterLink = styled('a')(() => ({
  fontSize: '1rem',
  fontWeight: 700,
  color: theme.color.textPrimary,
  textDecoration: 'none', // No underline by default
  transition: 'color 0.3s ease, textDecoration 0.3s ease', // Smooth transition for hover effect

  '&:hover': {
    textDecoration: 'underline', // Underline on hover
    color: theme.color.primary, // Optional: Change color on hover
  },

  '@media (max-width: 768px)': {
    fontSize: '0.8rem', // Smaller font size for mobile
  },

  '@media (max-width: 480px)': {
    fontSize: '0.7rem', // Even smaller font size for very small screens
  },
}));

const H1 = styled('h1')(() => ({
  color: theme.color.secondary,
  fontFamily: theme.fontFamily.primary,
  fontWeight: '1000',
  fontSize: 60,
  textAlign: 'center',
  margin: 0,
  textTransform: 'uppercase',
}));
const H2 = styled('h2')(() => ({
  color: theme.color.textPrimary,
  fontFamily: theme.fontFamily.primary,
  fontWeight: '700',
  fontSize: 40,
  textAlign: 'center',
  margin: 0,
}));

export default HeroVideo;
